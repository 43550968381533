.container {

    // border: 1px solid red;
    @media screen and (max-width: 700px) {
        .boxContainer {
            padding-top: 70px;
        }

        .footerFlex {
            margin: 50px 0;
            flex-wrap: wrap !important;
            gap: 45px;
            flex-direction: row;
            justify-content: flex-start;


        }
    }

}